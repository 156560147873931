import React from "react";

import { Route, Routes } from "react-router-dom";
import { Analyze } from "./ui/pages/Analyze";
function App() {
  return (
    <div className="app">
      <Analyze />
    </div>
  );
}

export default App;
