import { observer } from "mobx-react";
import { WAVEFORM_SAMPLE_RATE } from "../../../config";
import { useCanvas } from "../../../hooks/useCanvas";
import { audioStore } from "../../stores/AudioStore";
import { SectionDisplay } from "./Section";

type SectionsProps = {
  getCanvas?: () => HTMLCanvasElement | null;
  rerender?: () => void;
};
export const Sections: React.FC<SectionsProps> = observer((props) => {
  const canvas = useCanvas(props);

  const renderSections = () => {
    props.rerender!();
    if (!audioStore.data || !canvas) return;
    const sections = audioStore.sections;
    if (!sections) return;
    const ctx = canvas?.getContext("2d");
    if (!ctx) throw Error("invalid ctx");
    const sampleRatio = WAVEFORM_SAMPLE_RATE / audioStore.data.sampleRate;
    return sections?.map((section, index) => {
      const start = section.start + (section.startDelta || 0);
      const end = section.end + (section.endDelta || 0);

      if (audioStore.sampleToCanvasPixels(end) < audioStore.scrollLeft)
        return null;

      if (
        audioStore.sampleToCanvasPixels(start) >
        canvas.width + audioStore.scrollLeft
      )
        return null;
      return (
        <SectionDisplay
          key={index}
          {...{
            ...section,
            start,
            end,
            ctx,
            sampleRatio,
            index,
            rerender: props.rerender,
          }}
        />
      );
    });
  };

  return <>{renderSections()}</>;
});
