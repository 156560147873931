import { Canvas } from "./Canvas";
import { Waveform } from "./Waveform";
import { Sections } from "./Sections";
import { WAVEFORM_SCALE_X } from "../../../config";
import { observer } from "mobx-react";
import { ScrollBar } from "./ScrollBar";
import { Vector2 } from "../../../utils/Vector";
import { audioStore } from "../../stores/AudioStore";

export const Viewport = observer(() => {
  return (
    <div id="viewport">
      {!audioStore.data && (
        <span className="viewportText">Import a file to get started</span>
      )}
      <Canvas scale={new Vector2(WAVEFORM_SCALE_X, 1)}>
        <Waveform />
      </Canvas>
      <Canvas>
        <Sections />
      </Canvas>
      <Canvas>
        <ScrollBar />
      </Canvas>
    </div>
  );
});
