import { useEffect } from "react";
import { Vector2 } from "../utils/Vector";
import { useReferredState } from "./useReferredState";

export function useHover(center: Vector2, extent: Vector2) {
  const [isHovered, isHoveredRef, setIsHovered] = useReferredState(false);

  const getIsHovered = () => {
    return isHoveredRef.current;
  };
  const onMouseMove = (e: any) => {
    // if (isGrabbed()) {
    //   handleDrag(e);
    // } else {
    checkMouseHover(e);
    // }
  };

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, extent]);

  const onMouseEnter = () => {
    setIsHovered(true);
    document.body.style.cursor = "pointer";
  };
  const onMouseLeave = () => {
    setIsHovered(false);
    document.body.style.cursor = "default";
  };

  const checkMouseHover = (e: any) => {
    const offsetLeft = e.target.offsetParent?.offsetLeft || e.target.offsetLeft;
    const offsetTop = e.target.offsetParent?.offsetTop || e.target.offsetTop;
    const mouseX = e.pageX - offsetLeft + e.target.parentNode.scrollLeft;
    const mouseY = e.pageY - offsetTop;
    const start = center.clone().add(extent.clone().multiplyScalar(-0.5));
    const end = center.clone().add(extent.clone().multiplyScalar(0.5));
    if (end.x < start.x) {
      let startX = start.x;
      start.x = end.x;
      end.x = startX;
    }
    if (
      mouseX > start.x &&
      mouseX < end.x &&
      mouseY > start.y &&
      mouseY < end.y
    ) {
      if (!getIsHovered()) {
        onMouseEnter();
      }
    } else {
      if (getIsHovered()) {
        onMouseLeave();
      }
    }
  };

  return isHovered;
}
