import React, { useMemo } from "react";
import { useEffect, useRef } from "react";
import { CANVAS_HEIGHT } from "../../../config";
import { Vector2 } from "../../../utils/Vector";

type CanvasProps = {
  scale?: Vector2;
  height?: number;
};
export const Canvas: React.FC<CanvasProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const offscreenCanvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    createOffscreenCanvas();
  }, [canvasRef]);

  useEffect(() => {}, [canvasRef, offscreenCanvasRef]);

  const createOffscreenCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return null;
    const myOffScreenCanvas = document.createElement("canvas");
    myOffScreenCanvas.width = canvas.width;
    myOffScreenCanvas.height = canvas.height;
    offscreenCanvasRef.current = myOffScreenCanvas;
    if (props.scale) {
      const ctx = myOffScreenCanvas.getContext("2d");
      ctx?.scale(props.scale.x, props.scale.y);
    }
  };

  const clearCanvas = () => {
    const canvas = offscreenCanvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!ctx || !canvas) return;
    ctx.clearRect(
      0,
      0,
      canvas.width / (props.scale?.x || 1) || 1000,
      canvas.height || 1000
    );
  };

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        getCanvas: () => offscreenCanvasRef.current,
        rerender: () => clearCanvas(),
      });
    }
    return child;
  });

  const render = () => {
    const canvas = canvasRef.current;
    if (canvas && offscreenCanvasRef.current) {
      const dstCtx = canvas.getContext("2d");
      dstCtx?.clearRect(0, 0, canvas.width, canvas.height);
      dstCtx?.drawImage(offscreenCanvasRef.current, 0, 0);
    }
    requestAnimationFrame(render);
  };
  requestAnimationFrame(render);
  return (
    <canvas
      style={{
        width: "100%",
        height: props.height || CANVAS_HEIGHT,
        gridRow: 1,
        gridColumn: 1,
      }}
      ref={canvasRef}
    >
      {childrenWithProps}
    </canvas>
  );
};
