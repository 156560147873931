import React, { useEffect, useState } from "react";
import seedrandom from "seedrandom";
import {
  CANVAS_HEIGHT,
  WAVEFORM_LINE_WIDTH,
  WAVEFORM_SCALE_X,
} from "../../../config";
import { Vector2 } from "../../../utils/Vector";
import { audioStore, Section } from "../../stores/AudioStore";
import { MergeSectionButton } from "./shapes/MergeSectionButton";
import { Rectangle } from "./shapes/Rectangle";
import { SectionMarker } from "./shapes/SectionMarker";
import { Text } from "./shapes/Text";

type SectionProps = {
  ctx: CanvasRenderingContext2D;
  sampleRatio: number;
  index: number;
  rerender?: () => void;
} & Section;
export const SectionDisplay = (props: SectionProps) => {
  const [hue, setHue] = useState<number>(0);

  useEffect(() => {
    const hue = seedrandom(props.index.toString())() * 360;
    setHue(hue);
  }, [props.index]);

  const pixelsToSamples = (pixels: number) => {
    return Math.round(
      pixels / (props.sampleRatio * WAVEFORM_LINE_WIDTH * WAVEFORM_SCALE_X)
    );
  };
  const sampleToPixels = (sample: number) => {
    return audioStore.sampleToCanvasPixels(sample) - audioStore.scrollLeft;
  };
  const handleDragMarker = (delta: number, position: "start" | "end") => {
    pixelsToSamples(delta);
    if (position === "end") {
      audioStore.setSectionDelta({
        index: props.index,
        endDelta: pixelsToSamples(delta),
      });
    } else {
      audioStore.setSectionDelta({
        index: props.index,
        startDelta: pixelsToSamples(delta),
      });
    }
    props.rerender?.();
  };
  const { start, end, ctx, index, title } = props;

  const getText = () => {
    if (title) {
      return index + 1 + " " + title;
    }

    return "section " + (index + 1);
  };
  const renderMergeButton = () => {
    const nextSection = audioStore.sections[props.index + 1];
    if (!nextSection) return null;
    return (
      <MergeSectionButton
        index={props.index}
        hue1={hue}
        hue2={seedrandom((props.index + 1).toString())() * 360}
        ctx={ctx}
        position={
          new Vector2(sampleToPixels(end + (nextSection.start - end) / 2), 40)
        }
      />
    );
  };
  return (
    <>
      <SectionMarker
        hue={hue}
        ctx={ctx}
        position={new Vector2(sampleToPixels(start), 0)}
        onDrag={(delta) => handleDragMarker(delta, "start")}
        markerPosition="start"
      />
      <Rectangle
        ctx={ctx}
        hue={hue}
        start={new Vector2(sampleToPixels(start), 0)}
        end={new Vector2(sampleToPixels(end), CANVAS_HEIGHT)}
      />
      <Text
        ctx={ctx}
        fontSize={20}
        text={getText()}
        color={`hsla(${hue},70%, 70%, 1)`}
        position={new Vector2(sampleToPixels(start + (end - start) / 2), 20)}
      />
      <SectionMarker
        hue={hue}
        ctx={ctx}
        position={new Vector2(sampleToPixels(end), 0)}
        onDrag={(delta) => handleDragMarker(delta, "end")}
        markerPosition="end"
      />
      {renderMergeButton()}
    </>
  );
};
