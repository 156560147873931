import JSZip from "jszip";
import FileSaver from "file-saver";

type File = {
  content: Blob;
  filename: string;
};
export async function downloadZip(files: File[]) {
  renameDuplicateFiles(files);
  const zip = new JSZip();
  files.forEach((file) => {
    zip.file(file.filename + ".wav", file.content);
  });
  const zipFile = await zip.generateAsync({ type: "blob" });
  FileSaver.saveAs(zipFile, "download.zip");
}

function renameDuplicateFiles(files: File[]) {
  files.forEach((file) => {
    let filename = removeSpaces(file.filename);
    file.filename = "_"; //temporarily change to prevent checking against itself
    while (files.find((x) => x.filename === filename)) {
      const suffix = filename.split("-")[1];
      const base = filename.split("-")[0];
      if (!suffix) filename += "-2";
      else {
        filename = base + "-" + (parseInt(suffix) + 1);
      }
    }
    file.filename = filename;
  });
}
function removeSpaces(line: string) {
  const words = line.split(" ");
  return words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
}
