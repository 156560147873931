import { useEffect } from "react";
import { Vector2 } from "../../../../utils/Vector";

type TextProps = {
  fontSize: number;
  color: string;
  ctx: CanvasRenderingContext2D;
  text: string;
  position: Vector2;
};
export const Text = (props: TextProps) => {
  useEffect(() => {
    drawText();
  }, [props.text, props.position, props.color]);

  const drawText = () => {
    const { ctx, fontSize, color, text, position } = props;
    ctx.textAlign = "center";
    ctx.fillStyle = color || "black";
    ctx.font = `bold ${fontSize || 75}px Arial`;
    ctx.fillText(text, position.x, position.y);
  };

  return null;
};
