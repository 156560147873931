import { Button, Modal } from "antd";
import { useState } from "react";

export const About = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button onClick={() => setVisible(!visible)}>About</Button>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="About"
        footer={null}
      >
        This tool can be used to cut a long track of voice lines into separate
        voicelines.
        <br />
        <br />
        features:
        <ul>
          <li>Automatically detect sections</li>
          <li>Detect file names based on content of sections</li>
          <li>Merge sections</li>
          <li>Download archive with all sections</li>
        </ul>
        <br />
        Made by{" "}
        <a href="https://twitter.com/Joost3D" target="_blank" rel="noreferrer">
          Joost
        </a>
      </Modal>
    </>
  );
};
