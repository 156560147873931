import { useEffect, useMemo } from "react";
import { useGrab } from "../../../../hooks/useGrab";
import { Vector2 } from "../../../../utils/Vector";

type MarkerProps = {
  ctx: CanvasRenderingContext2D;
  position: Vector2;
  hue: number;
  onDrag: (delta: number) => void;
  markerPosition: "start" | "end";
};

export const SectionMarker = (props: MarkerProps) => {
  const direction = props.markerPosition === "start" ? 1 : -1;
  const extent = new Vector2(direction * -20, 20);

  const [isHovered, delta] = useGrab(
    props.position.clone().add(extent.clone().multiplyScalar(0.5)),
    extent
  );

  useEffect(() => {
    draw();
  }, [props.hue, props.position, isHovered]);

  useEffect(() => {
    if (delta !== 0) {
      props.onDrag(delta);
    }
  }, [delta]);

  const color = useMemo(() => {
    if (isHovered) return "white";
    return `hsla(${props.hue},70%, 70%, 1)`;
  }, [isHovered, props.hue]);
  const draw = () => {
    const { ctx, position } = props;
    ctx.fillStyle = color;
    const { x, y } = position;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, extent.y);
    ctx.lineTo(x - extent.y * direction, y);
    ctx.lineTo(x, y);
    ctx.fill();
  };
  const debug_drawBounds = () => {
    const { ctx, position } = props;

    const start = position;
    const end = position.clone().add(extent);

    ctx.fillStyle = `hsla(${Math.random() * 360},70%, 70%, .1)`;
    ctx.fillRect(start.x, start.y, end.x - start.x, end.y - start.y);
  };
  return null;
};
