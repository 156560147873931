import { Button, Modal, Input, Form, message } from "antd";
import axios from "axios";
import { useState } from "react";
import { showError } from "../../utils/utils";

export const Feedback = () => {
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const submitFeedback = async (values: any) => {
    setSubmitting(true);
    try {
      await axios.post("/api/feedback", values);
      message.success("Thank you for submitting your feedback!");
    } catch (error) {
      showError("Failed to submit feedback", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Button onClick={() => setVisible(!visible)}>Feedback</Button>
      <Modal
        onOk={submitFeedback}
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Submit Feedback"
        footer={null}
      >
        Do you have any feedback or have you spotted a bug? Let me know!
        <br />
        <br />
        <Form
          name="basic"
          onFinish={submitFeedback}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Feedback"
            name="feedback"
            rules={[{ required: true, message: "Please enter something" }]}
          >
            <Input.TextArea style={{ height: 200 }} />
          </Form.Item>

          <Form.Item
            label="Your Email Address (optional)"
            name="email"
            rules={[{ type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        <br />
      </Modal>
    </>
  );
};
