import { FileImport } from "../components/FileImport";
import { Viewport } from "../components/viewport/Viewport";
import { SectionList } from "../components/SectionList";
import { InputNumber } from "antd";
import { audioStore } from "../stores/AudioStore";
import { observer } from "mobx-react";
import { About } from "../components/About";
import { Feedback } from "../components/Feedback";
import { Spacer } from "../components/Spacer";

export const Analyze = () => {
  return (
    <div>
      <Viewport />
      <br />
      <QuietTresholdInput />
      <br />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FileImport />
        <SectionList />
      </div>
      <div
        style={{ position: "fixed", right: 10, bottom: 10, display: "flex" }}
      >
        <About />
        <Spacer />
        <Feedback />
      </div>
    </div>
  );
};

const QuietTresholdInput = observer(() => {
  return (
    <div>
      Quiet Duration Treshold{" "}
      <InputNumber
        step={0.1}
        value={audioStore.quietTreshold}
        onChange={audioStore.setQuietTreshold}
      />
    </div>
  );
});
