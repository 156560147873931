import { useEffect } from "react";
import { useHover } from "../../../../hooks/useHover";
import { Vector2 } from "../../../../utils/Vector";
import { audioStore } from "../../../stores/AudioStore";
import IconLoader from "../IconLoader";

export const MergeSectionButton = (props: {
  hue1: number;
  hue2: number;
  ctx: CanvasRenderingContext2D;
  position: Vector2;
  index: number;
}) => {
  const extent = new Vector2(38, 38);
  const isHovered = useHover(props.position, extent);

  useEffect(() => {
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.position, isHovered]);

  const onClick = () => {
    if (isHovered) {
      audioStore.mergeSections(props.index);
      console.log("merge!!");
    }
  };
  useEffect(() => {
    draw();
  }, [props.hue1, props.hue2, props.position, isHovered]);

  const getColor = (hue: number) => {
    if (isHovered) return "white";
    return `hsla(${hue},70%, 70%, 1)`;
  };
  const draw = async () => {
    const { ctx, position, hue1, hue2 } = props;
    ctx.clearRect(
      position.x - extent.x / 2,
      position.y - extent.y / 2,
      extent.x,
      extent.y
    );
    const image = await IconLoader.getImage("/merge.svg");
    ctx.drawImage(
      image,
      position.x - extent.x / 2,
      position.y - extent.y / 2,
      extent.x,
      extent.y
    );

    ctx.globalCompositeOperation = "source-atop";
    ctx.fillStyle = getColor(hue1);
    ctx.fillRect(
      position.x - extent.x / 2,
      position.y - extent.y / 2,
      extent.x / 2,
      extent.y
    );
    ctx.fillStyle = getColor(hue2);

    ctx.fillRect(position.x, position.y - extent.y / 2, extent.x / 2, extent.y);

    ctx.globalCompositeOperation = "source-over";
  };
  return null;
};
