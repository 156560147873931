class IconLoader {
  images: { [url: string]: HTMLImageElement } = {};

  async getImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      if (this.images[url]) {
        resolve(this.images[url]);
      }

      const image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.src = url;
      this.images[url] = image;
    });
  }
}

export default new IconLoader();
