import { Modal, message } from "antd";
import {
  CANVAS_PADDING,
  WAVEFORM_LINE_WIDTH,
  WAVEFORM_SAMPLE_RATE,
  WAVEFORM_SCALE_X,
} from "../config";
import { audioStore, Section } from "../ui/stores/AudioStore";
import { encodeWAV } from "./encodeWav";

export function showError(
  title: string,
  error: any,
  type?: "modal" | "message"
) {
  const errorMessage =
    error.response?.data?.message ||
    error.response?.data ||
    error.message ||
    error;
  if (type === "message") {
    message.error(title + " " + errorMessage, 10);
  } else {
    Modal.error({
      title,
      content: errorMessage,
    });
  }
}

export function resample(
  source: Float32Array,
  sourceSampleRate: number,
  targetSampleRate: number
) {
  if (sourceSampleRate === targetSampleRate) return source;

  const ratio = sourceSampleRate / targetSampleRate;
  const res = new Float32Array(source.length / ratio);
  for (let i = 0; i < source.length / ratio; i++) {
    res[i] = source[Math.round(i * ratio)];
  }

  return res;
}

export async function resample2(
  source: Float32Array,
  sourceSampleRate: number,
  targetSampleRate: number
) {
  const context = new AudioContext();

  const audioBuffer = context.createBuffer(1, source.length, sourceSampleRate);
  audioBuffer.copyToChannel(source, 0);
  var offlineCtx = new OfflineAudioContext(
    1,
    audioBuffer.duration * targetSampleRate,
    targetSampleRate
  );

  var offlineSource = offlineCtx.createBufferSource();
  offlineSource.buffer = audioBuffer;
  offlineSource.connect(offlineCtx.destination);
  offlineSource.start();
  const resampled = await offlineCtx.startRendering();
  return resampled.getChannelData(0);
}

export function getSectionWav(section: Section) {
  return encodeWAV(
    audioStore.getSectionPCMData(section),
    1,
    audioStore.data?.sampleRate || 44100,
    1
  );
}

export function clamp(x: number, min: number, max: number) {
  return Math.max(min, Math.min(x, max));
}

export function calculateCanvasWidth(sampleCount: number, sampleRate: number) {
  const sampleRatio = WAVEFORM_SAMPLE_RATE / sampleRate;
  return (
    sampleCount * sampleRatio * WAVEFORM_LINE_WIDTH * WAVEFORM_SCALE_X +
    CANVAS_PADDING
  );
}
