import { useEffect, useState } from "react";
import { Vector2 } from "../utils/Vector";
import { useHover } from "./useHover";

export function useGrab(center: Vector2, extent: Vector2) {
  const isHovered = useHover(center, extent);
  const [grabStart, setGrabStart] = useState({ x: 0, y: 0 });
  const [isGrabbed, setIsGrabbed] = useState(false);
  const [delta, setDelta] = useState(0);
  const [initialDelta, setInitialDelta] = useState(0);
  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, isGrabbed]);

  const onMouseMove = (e: any) => {
    if (isGrabbed) {
      setDelta(e.pageX - grabStart.x + initialDelta);
    }
  };

  const onMouseDown = (e: any) => {
    if (!isHovered) return;
    setInitialDelta(delta);
    setGrabStart({ x: e.pageX, y: e.pageY });
    setIsGrabbed(true);
  };

  const onMouseUp = () => {
    setIsGrabbed(false);
  };

  return [isHovered || isGrabbed, delta] as [boolean, number];
}
