import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { audioStore } from "../../stores/AudioStore";
import { resample } from "../../../utils/utils";
import {
  CANVAS_PADDING,
  WAVEFORM_LINE_WIDTH,
  WAVEFORM_SAMPLE_RATE,
  WAVEFORM_SCALE_X,
} from "../../../config";
import { useCanvas } from "../../../hooks/useCanvas";

type Props = {
  getCanvas?: () => HTMLCanvasElement | null;
  rerender?: () => void;
};
export const Waveform: React.FC<Props> = observer((props) => {
  const canvas = useCanvas(props);
  const getSamples = useMemo(() => {
    if (!audioStore.data) return;
    const { sampleRate, pcmData } = audioStore.data;
    return resample(pcmData, sampleRate, WAVEFORM_SAMPLE_RATE);
  }, [audioStore.data]);

  useEffect(() => {
    props.rerender!(); // rerender waveform when a new audio file is loaded in
    // const t0 = performance.now();
    render();
    // console.log(
    //   "Rendered waveform in " + Math.round(performance.now() - t0) + "ms"
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSamples, canvas, audioStore.scrollLeft]);

  const render = async () => {
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) throw Error("invalid ctx");
    const canvasWidth = canvas.width;
    ctx.lineWidth = WAVEFORM_LINE_WIDTH;
    ctx.strokeStyle = "#17a4dc";
    if (!getSamples?.length) return;
    const startIndex = Math.round(
      audioStore.scrollLeft / WAVEFORM_SCALE_X / WAVEFORM_LINE_WIDTH
    );
    for (let i = startIndex; i < getSamples.length; i++) {
      const x = i * WAVEFORM_LINE_WIDTH + CANVAS_PADDING;
      if (x > (canvasWidth + audioStore.scrollLeft) / WAVEFORM_SCALE_X) return;
      drawLineSegment(
        ctx,
        x - audioStore.scrollLeft / WAVEFORM_SCALE_X,
        getSamples[i] * 500 + 1,
        canvas.height
      );
    }
  };

  const drawLineSegment = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    height: number
  ) => {
    const startY = height / 2;
    ctx.beginPath();
    ctx.moveTo(x, startY);
    ctx.lineTo(x, startY + y);
    ctx.stroke();
  };

  return null;
});
