import { useEffect } from "react";
import { Vector2 } from "../../../../utils/Vector";

export const Rectangle = (props: {
  hue: number;
  ctx: CanvasRenderingContext2D;
  start: Vector2;
  end: Vector2;
}) => {
  useEffect(() => {
    draw();
  }, [props.hue, props.start, props.end]);

  const draw = () => {
    const { ctx, hue, start, end } = props;
    ctx.fillStyle = `hsla(${hue},70%, 70%, .1)`;
    ctx.fillRect(start.x, start.y, end.x - start.x, end.y - start.y);
  };
  return null;
};
