import { Button, Card, Input, Progress, Spin, Table, Tooltip } from "antd";
import { observer } from "mobx-react";
import { audioStore, Section } from "../stores/AudioStore";
import {
  DownloadOutlined,
  PlayCircleFilled,
  CloudSyncOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import { getSectionWav, showError } from "../../utils/utils";
import { detectVoicelineName } from "../../core/core";
import FileSaver from "file-saver";
import { useState } from "react";
import { encodeWAV } from "../../utils/encodeWav";
import { downloadZip } from "../../utils/downloadZip";
import seedrandom from "seedrandom";

export const SectionList = observer(() => {
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      render: (x: Section) => (
        <div
          style={{
            width: 16,
            height: 16,
            background: `hsla(${
              seedrandom(x.index!.toString())() * 360
            },70%, 70%, 1)`,
          }}
        />
      ),
      key: "color",
      width: 16,
    },
    {
      render: (x: Section) => x.index! + 1,
      key: "#",
      width: 16,
    },
    {
      render: (x: Section) => (
        <Input
          onChange={(e) => audioStore.setSectionTitle(e.target.value, x.index!)}
          value={x.title}
          defaultValue={"section " + (x.index! + 1)}
        />
      ),
      key: "title",
    },
    {
      render: (x: Section) => (
        <>
          <PlayButton section={x} />{" "}
          <DownloadSectionButton section={x} index={x.index!} />{" "}
          <DetectSectionName index={x.index!} section={x} />
        </>
      ),
      key: "actions",
      width: 128,
    },
  ];

  const goToSection = (index: number) => {
    audioStore.setScrollLeft(
      audioStore.sampleToCanvasPixels(audioStore.sections[index].start - 5000)
    );
  };
  if (!audioStore.sections.length) return null;

  return (
    <Card style={{ flex: 1 }}>
      Found {audioStore.sections.length} sections
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Table
          onRow={(section, rowIndex) => {
            return {
              onClick: () => {
                goToSection(section.index!);
              },
            };
          }}
          showHeader={false}
          pagination={{
            pageSize,
            onChange(_, pageSize) {
              setPageSize(pageSize);
            },
          }}
          style={{
            cursor: "pointer",
            flex: 1,
            margin: 20,
            maxHeight: 600,
            overflow: "auto",
          }}
          dataSource={audioStore.sections.map((x, index) => {
            x.index = index;
            return x;
          })}
          columns={columns}
        />
        <div style={{ margin: "20px 40px" }}>
          <DetectNamesButton />
          <br />
          <br />
          <DownloadArchiveButton />
        </div>
      </div>
    </Card>
  );
});

type PlayButtonProps = {
  section: Section;
};

export const PlayButton = (props: PlayButtonProps) => {
  return (
    <Tooltip title="Play Section">
      <PlayCircleFilled
        style={{ fontSize: 24 }}
        onClick={() => playSection(props.section)}
      />
    </Tooltip>
  );
};
function playSection(section: Section) {
  const object = new Blob([getSectionWav(section)]);
  const url = URL.createObjectURL(object);
  new Audio(url).play();
}

export const DownloadSectionButton = (props: {
  section: Section;
  index: number;
}) => {
  return (
    <Tooltip title="Download Section">
      <DownloadOutlined
        style={{ fontSize: 24 }}
        onClick={() => downloadSection(props.section, props.index)}
      />
    </Tooltip>
  );
};

function downloadSection(section: Section, index: number) {
  const file = new Blob([getSectionWav(section)], { type: "audio/wav" });

  FileSaver.saveAs(file, (section.title || `section${index + 1}`) + ".wav");
}

type Props = {
  section: Section;
  index: number;
};
const DetectSectionName = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const detectName = async () => {
    setLoading(true);
    await detectVoicelineName(props.section, props.index);
    setLoading(false);
  };

  return (
    <Tooltip title="Detect Name">
      {isLoading ? (
        <Spin />
      ) : (
        <CloudSyncOutlined style={{ fontSize: 24 }} onClick={detectName} />
      )}
    </Tooltip>
  );
};

const DetectNamesButton = () => {
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const detectNames = async () => {
    setLoading(true);
    setProgress(0);
    try {
      if (!audioStore.data) throw Error("Nothing to upload");
      const sections = audioStore.sections;
      if (!sections) throw Error("no sections found");
      for (const [index, section] of sections.entries()) {
        await detectVoicelineName(section, index);
        setProgress(Math.round(((index + 1) / sections.length) * 100));
      }
    } catch (error) {
      showError("Failed to detect names", error);
    } finally {
      setLoading(false);
    }
  };
  if (isLoading) {
    return (
      <div>
        <img
          className="spin"
          src="gear.svg"
          alt="gear"
          style={{ width: 16, filter: "invert(78%)" }}
        />
        Detecting Filenames
        <Progress status="active" percent={progress} />
      </div>
    );
  }
  return (
    <Button className=" button" type="primary" onClick={detectNames}>
      <CloudSyncOutlined />
      Detect Filenames
    </Button>
  );
};

const DownloadArchiveButton = () => {
  const [isLoading, setLoading] = useState(false);

  async function downloadSections() {
    setLoading(true);
    try {
      if (!audioStore.data) return;

      const { sampleRate, pcmData } = audioStore.data;
      const files: { filename: string; content: Blob }[] = [];
      audioStore.sections?.forEach((section, index) => {
        const file = new Blob(
          [
            encodeWAV(
              pcmData.slice(
                section.start + (section.startDelta || 0),
                section.end + (section.endDelta || 0)
              ),
              1,
              sampleRate,
              1
            ),
          ],
          { type: "audio/wav" }
        );
        files.push({ filename: section.title || "", content: file });
      });
      await downloadZip(files);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  if (isLoading) {
    return (
      <div>
        <img
          className="spin"
          src="gear.svg"
          alt="gear"
          style={{ width: 16, filter: "invert(78%)" }}
        />
        Downloading Zip
      </div>
    );
  }
  return (
    <Button className=" button" type="primary" onClick={downloadSections}>
      <FileZipOutlined />
      Download All Sections
    </Button>
  );
};
