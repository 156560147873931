import { Button, Card, Spin, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import React, { useState } from "react";
import { audioStore } from "../stores/AudioStore";
import { UploadOutlined } from "@ant-design/icons";
import { showError } from "../../utils/utils";
export const FileImport = () => {
  const [highlight, setHighlight] = useState(false);
  const [file, setFile] = useState<UploadFile<any>>();
  const [isImporting, setIsImporting] = useState(false);
  const handleChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    setIsImporting(true);
    try {
      setFile(info.fileList[0]);
      const file = info.fileList[0].originFileObj;
      if (!file) throw Error("No file");
      await handleImport(file);
    } catch (error) {
      showError("Failed to load file", error);
    } finally {
      setIsImporting(false);
    }
  };
  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    setIsImporting(true);
    e.preventDefault();
    setHighlight(false);

    const { files } = e.dataTransfer;
    if (files) {
      await handleImport(files[0]);
    }
    setIsImporting(false);
  };

  const handleImport = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async (e) => {
        if (!e.target?.result) return reject("Failed to load file.");
        const context = new AudioContext();
        const audioBuffer = await context.decodeAudioData(
          e.target.result as ArrayBuffer
        );
        audioStore.setAudioData({
          pcmData: audioBuffer.getChannelData(0),
          sampleRate: audioBuffer.sampleRate,
        });
        resolve(true);
      };
    });
  };
  const onDragOver = (e: any) => {
    e.preventDefault();
    setHighlight(true);
  };
  const onDragLeave = (e: any) => {
    e.preventDefault();
    setHighlight(false);
  };

  const renderBoxText = () => {
    if (isImporting)
      return (
        <>
          <Spin size="large" /> Importing
        </>
      );
    return (
      <>
        <UploadOutlined style={{ fontSize: 40 }} />
        Drag File To Import
      </>
    );
  };

  const loadSample = async () => {
    try {
      const res = await fetch("/sample.mp3");
      if (!res.body) throw Error("Invalid response body");
      await handleImport(await res.blob());
    } catch (error) {
      showError("Failed to load sample ", error);
    }
  };
  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          id="drop_zone"
          style={{
            borderColor: highlight ? "#177ddc" : "#c8c8c850",
          }}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          {renderBoxText()}
        </div>
        <br />
        <Upload
          maxCount={1}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleChange}
          fileList={file ? [file] : []}
        >
          <Button size="large" type="primary">
            Import File
          </Button>
        </Upload>
        <br />
        <Button size="large" type="primary" onClick={loadSample}>
          Try a Sample
        </Button>
      </div>
    </Card>
  );
};
