import axios from "axios";
import { audioStore, Section } from "../ui/stores/AudioStore";
import { resample2, showError } from "../utils/utils";

const SILENCE_TRESHOLD = 0.002;
const STEPS_PER_SECOND = 20;
const PADDING_DURATION = 0.1;

export function findSections(pcmdata: Float32Array, samplerate: number) {
  let index = 0;
  const stepSize = samplerate / STEPS_PER_SECOND;

  const steps: Step[] = [];
  //calculate moving average of volume for each step
  while (index + stepSize < pcmdata.length) {
    let averageVolume = 0;
    // iterate over each sample in a step to calculate average volume
    for (var i = index; i < index + stepSize; i++) {
      averageVolume += Math.abs(pcmdata[i]);
    }
    averageVolume /= stepSize;
    //if this step is quiet, cut the voiceline
    steps.push({
      averageVolume,
      quietDuration: 0,
      start: index,
    });
    index += stepSize;
  }
  analyzeSteps(steps);

  const padding = stepSize * PADDING_DURATION * STEPS_PER_SECOND;
  let sectionStartIndex = 0;
  const res: Section[] = [];
  steps.forEach((step, index) => {
    if (index < sectionStartIndex) return;
    if (
      step.quietDuration > audioStore.quietTreshold * STEPS_PER_SECOND ||
      index === steps.length - 1
    ) {
      // console.log(
      //   "Section length: " +
      //     (step.start - steps[sectionStartIndex].start) / samplerate
      // );
      if (step.start - steps[sectionStartIndex].start > 0.1 * samplerate) {
        //ignore anything under 0.1 second
        res.push({
          start: Math.max(0, steps[sectionStartIndex].start - padding),
          end: step.start + padding,
        });
      }

      //   sections.push(
      //     pcmdata.slice(
      //       Math.max(0, steps[sectionStartIndex].start - padding),
      //       step.start + padding
      //     )
      //   );

      sectionStartIndex = index + step.quietDuration;
    }
  });

  // console.log("number of sections: " + sections.length);
  return res;
}

type Step = {
  start: number;
  averageVolume: number;
  quietDuration: number;
};
function analyzeSteps(steps: Step[]) {
  steps.forEach((currentStep, index) => {
    let i = index;
    while (i < steps.length - 1) {
      i++;
      if (steps[i].averageVolume > SILENCE_TRESHOLD) {
        break;
      }
    }
    currentStep.quietDuration = i - index;
  });
}

export async function detectVoicelineName(section: Section, index: number) {
  if (!audioStore.data) throw Error("Nothing to upload");

  const formData = new FormData();
  formData.append(
    "data",
    new Blob([
      await resample2(
        audioStore.getSectionPCMData(section),
        audioStore.data.sampleRate,
        16000
      ),
    ])
  );

  try {
    const res: any = await axios.post("/api/detect-filename", formData);
    audioStore.setSectionTitle(res.data, index);
  } catch (error) {
    showError(
      "Failed to detect name for section " + (index + 1),
      error,
      "message"
    );
  }
}
