export class Vector2 {
  constructor(public x: number, public y: number) {}
  add(b: Vector2) {
    this.x += b.x;
    this.y += b.y;
    return this;
  }
  multiplyScalar(x: number) {
    this.x *= x;
    this.y *= x;
    return this;
  }
  clone() {
    return new Vector2(this.x, this.y);
  }
  multiply(b: Vector2) {
    this.x *= b.x;
    this.y *= b.y;
    return this;
  }
}
