import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { CANVAS_HEIGHT } from "../../../config";
import { useCanvas } from "../../../hooks/useCanvas";
import { useGrab } from "../../../hooks/useGrab";
import { calculateCanvasWidth, clamp } from "../../../utils/utils";
import { Vector2 } from "../../../utils/Vector";
import { audioStore } from "../../stores/AudioStore";
type Props = {
  getCanvas?: () => HTMLCanvasElement | null;
  rerender?: () => void;
};

export const ScrollBar = observer((props: Props) => {
  const canvas = useCanvas(props);
  const height = 15;

  const scrollbarRatio = useMemo(() => {
    if (!audioStore.data || !canvas) return 1;
    const { pcmData, sampleRate } = audioStore.data;
    const totalWidth = calculateCanvasWidth(pcmData.length, sampleRate);
    return canvas.width / totalWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioStore.data, canvas]);
  const width = useMemo(() => {
    if (!canvas) return 500;
    return scrollbarRatio * canvas.width;
  }, [canvas, scrollbarRatio]);
  const getStartEnd = useMemo(() => {
    const canvasHeight = canvas?.height || CANVAS_HEIGHT;
    const startX = audioStore.scrollLeft * scrollbarRatio;
    return {
      center: new Vector2(startX + width / 2, canvasHeight - height / 2),
      extent: new Vector2(Math.max(width, 100), height),
      start: new Vector2(startX, canvasHeight - height),
      end: new Vector2(width, canvasHeight),
    };
  }, [canvas?.height, scrollbarRatio, width, audioStore.scrollLeft]);

  const [isHovered, delta] = useGrab(getStartEnd.center, getStartEnd.extent);
  useEffect(() => {
    props.rerender!();
    render();
  }, [isHovered, audioStore.scrollLeft, audioStore.data]);
  useEffect(() => {
    const canvasWidth = canvas?.width || 500;
    audioStore.setScrollLeft(
      clamp(delta, 0, canvasWidth - width) / scrollbarRatio
    );
  }, [canvas, delta]);
  const render = () => {
    if (scrollbarRatio >= 1) return;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    //render track first
    ctx.fillStyle = "#0e0e0e";
    ctx.fillRect(0, canvas.height - height, canvas.width, canvas.height);
    //render actual scrollbar
    ctx.fillStyle = isHovered ? "#aaa" : "#444";
    const { start, end } = getStartEnd;
    ctx.fillRect(start.x, start.y, end.x, end.y);
  };

  return null;
});
