import { action, makeAutoObservable, observable } from "mobx";
import {
  CANVAS_PADDING,
  WAVEFORM_LINE_WIDTH,
  WAVEFORM_SAMPLE_RATE,
  WAVEFORM_SCALE_X,
} from "../../config";
import { findSections } from "../../core/core";

type AudioData = {
  pcmData: Float32Array;
  sampleRate: number;
};
export type Section = {
  start: number;
  startDelta?: number;
  end: number;
  endDelta?: number;
  title?: string;
  index?: number;
};
class AudioStore {
  @observable data?: AudioData;
  @observable sections: Section[] = [];
  @observable scrollLeft = 0;
  @observable quietTreshold = 0.3;

  constructor() {
    makeAutoObservable(this);
  }
  @action
  setAudioData(data: AudioData) {
    this.data = data;
    this.sections = findSections(data.pcmData, data.sampleRate);
  }

  @action
  setSectionDelta({
    index,
    startDelta,
    endDelta,
  }: {
    index: number;
    startDelta?: number;
    endDelta?: number;
  }) {
    const section = this.sections[index];
    if (!section) throw Error("Invalid section ");
    section.startDelta = startDelta || section.startDelta;
    section.endDelta = endDelta || section.endDelta;
    // this.sections = [...this.sections];
  }
  @action
  setSectionTitle(title: string, index: number) {
    const section = this.sections[index];
    if (!section) throw Error("invalid section index");

    section.title = title.replace(/\./g, "");

    this.sections = [...this.sections]; //force update manually
  }
  getSectionPCMData(section: Section) {
    if (!this.data) throw Error("No data");
    return this.data.pcmData.slice(
      section.start + (section.startDelta || 0),
      section.end + (section.endDelta || 0)
    );
  }
  @action
  setScrollLeft(position: number) {
    this.scrollLeft = position;
  }

  sampleToCanvasPixels(sampleIndex: number) {
    if (!this.data) return 0;
    const sampleRatio = WAVEFORM_SAMPLE_RATE / this.data.sampleRate;

    return (
      sampleIndex * sampleRatio * WAVEFORM_LINE_WIDTH * WAVEFORM_SCALE_X +
      CANVAS_PADDING * WAVEFORM_SCALE_X
    );
  }

  @action
  setQuietTreshold = (x: number) => {
    this.quietTreshold = x;
    if (this.data) {
      this.sections = findSections(this.data.pcmData, this.data.sampleRate);
    }
  };

  @action
  mergeSections = (index: number) => {
    this.sections[index].end = this.sections[index + 1].end;
    this.sections.splice(index + 1, 1);
  };
}
export const audioStore = new AudioStore();
